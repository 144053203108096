// import { AutomatitCarousel } from './automatit_carousel.m';
import { contactSubmit } from './contact.js';
import { initMobileNav, doubleClick } from "./header";
import { toggleRatesMap, facCaro, comingSoon } from './facility.js';
import { maps } from './map.js';
import { initReviewsCaro } from './reviews.js';

// const homeCaro = {
// 	init: function () {
// 		const caro = document.querySelectorAll('.carousel');
// 		caro.forEach(ele => {
// 			window.homeCaro = AutomatitCarousel({
// 				element: ele,
// 				speed: 1000,
// 				intervalTiming: false,
// 				showDots: false,
// 				// easing: 'easeInOutExpo',
// 				/* eslint-disable */
// 			// Potential text slide??
// 			afterSlide: function(prev, current){}
// 		});
// 	})
// 	}
// };

// const initHomeCaro =() => homeCaro.init();

// if (document.querySelectorAll('.carousel')) {
// 	initHomeCaro();
// }

if (document.getElementById('contact_form')) {
	contactSubmit();
}

initMobileNav();
doubleClick();

//functionality for dropdown arrows in faqs page
const faqDropBtns = document.querySelectorAll('.faq_question');
[...faqDropBtns].forEach(dropBtn => {
	dropBtn.addEventListener('click', (event) => {
		console.log(event);
		const answer = event.currentTarget.closest('.faq_item').querySelector('.faq_answer');
		answer.classList.toggle('open');
		const arrow = event.currentTarget.closest('.faq_item').querySelector('.faq_arrow');
		arrow.classList.toggle('open');
	});
});

//functionality for dropdown arrows in tips page
const tipsDropBtns = document.querySelectorAll('.feat_select');
[...tipsDropBtns].forEach(dropBtn => {
	dropBtn.addEventListener('click', (event) => {
		const answer = event.currentTarget.closest('.feat_item').children[1].children[0];
		answer.classList.toggle('open');
		const arrow = event.currentTarget.closest('.feat_item').children[2];
		arrow.classList.toggle('open');
	});
});

const tipsTitle = document.querySelector('.tips_title');
const faqTitle = document.querySelector('.faq_title');
const faqContent = document.querySelector('#faq_container');
const tipsContent = document.querySelector('#tips_container');

if (faqTitle) {
	tipsTitle.addEventListener('click', () => {
		tipsTitle.classList.add('active');
		faqTitle.classList.remove('active');
		faqTitle.firstChild.classList.remove('active');
		tipsTitle.firstChild.classList.add('active');
		faqContent.style.display = 'none';
		tipsContent.style.display = 'block';
	});
	
	faqTitle.addEventListener('click', () => {
		faqTitle.classList.add('active');
		tipsTitle.classList.remove('active');
		faqTitle.firstChild.classList.add('active');
		tipsTitle.firstChild.classList.remove('active');
		faqContent.style.display = 'block';
		tipsContent.style.display = 'none';
	});
}

if(faqContent){
	const faqArrows = document.querySelectorAll('.faq_arrow');
	const faqAnswers = document.querySelectorAll('.faq_answer');
	faqArrows[0].classList.add('open');
	faqAnswers[0].classList.add('open');
}

toggleRatesMap.init();
facCaro.init();

if (document.querySelector('#locations_map')) {
	maps();
}

if (document.getElementById('coming_soon_form')) {
	comingSoon.init();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}