import { AutomatitCarousel } from './automatit_carousel.m';
// disable linting for this file
/* eslint-disable */

export const toggleRatesMap = ( () => {

	let options = {};

	options.init = () => {
		if (document.querySelector('.internal_title') !== null) {

			document.querySelectorAll('.internal_title').forEach((el) => { 
				el.addEventListener('click', () => { 
					document.querySelectorAll('.internal_title').forEach((title) => title.classList.remove('active'))
					el.classList.add('active');
					let currentTab = el.getAttribute('data-tab');
					document.querySelectorAll('.tab_content').forEach((tab) => { 
						if (tab.getAttribute('id') === currentTab) {
							document.querySelector('.tab_content').style.display = 'none';
							tab.style.display = 'block';
						} else {
							tab.style.display = 'none';
						}
					});
				});
			});

		}
	};

	return options;
})();

export const facCaro = (function(){
	let options = {};

	options.init = function(){
		if(document.getElementById('facility_carousel')){
			AutomatitCarousel({
				element: document.getElementById('facility_carousel'),
				showDots: false,
				useChevrons: true,
				imagesAsBackgrounds: true,
				images: window.facCaroImgs,
				showDots: true,
			});
		}
	};

	return options;
})();

export const comingSoon = (function () {
	var subIP = false;
	let options = {};
	options.init = function () {
		document.addEventListener('DOMContentLoaded', function () {
			document.getElementById('coming_soon_form').addEventListener('submit', function (e) {
				if (document.getElementById('hp').value != '') return false;
				Array.prototype.forEach.call(document.querySelectorAll('#coming_soon_form .error'), function (error) {
					error.classList.remove('error')
				});
				document.querySelector('#coming_soon_form .response_message').innerHTML = '';
				if (subIP) {
					return;
				}
				var checkFields = new Array('name', 'email', 'message');
				var errorsFound = new Array();
				checkFields.forEach(function (item) {
					if (document.getElementById(item + '_input').value == '') {
						document.getElementById(item + '_input').classList.add('error');
						errorsFound.push(item);
					}
				});
				if (!subIP && errorsFound.length == 0) {
					subIP = true;
					var request = new XMLHttpRequest(),
						method = 'POST',
						url = document.getElementById('coming_soon_form').getAttribute('action'),
						data = new FormData(document.getElementById('coming_soon_form'));
					request.onreadystatechange = function () {
						if (request.readyState === 4 && request.status === 200) {
							console.log(request.responseText);
							var response = JSON.parse(request.responseText);
							if (response.ret_det.success) {
								var input = document.getElementById('coming_soon_form_submit');
								input.parentElement.removeChild(input);
								document.querySelector('#coming_soon_form .response_message').innerHTML = "<span class='success'>" + response.ret_det.message + "</span>";
							} else {
								if (typeof response.data.error_fields !== 'undefined') {
									var errors = [];
									for (var x = 0; x < response.data.error_fields.length; x++) {
										errors.push(response.data.error_fields[x].field_name);
									}
									updateResponseMessage(errors);
								} else {
									document.querySelector('#coming_soon_form .response_message').innerHTML = "<span class='error'>" + response.ret_det.message + "</span>";
								}
							}
							subIP = false;
						}
					}
					request.open(method, url, true);
					request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
					request.send(data);
				}
				else if (errorsFound.length > 0) {
					updateResponseMessage(errorsFound);
				}
				else {
					document.querySelector('#coming_soon_form .response_message').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
				}
				e.stopImmediatePropagation();
				e.preventDefault();
			});
		});

		function updateResponseMessage(errors){
			var response = '';
			if(errors.length == 1){
				response = "The "+errors[0]+" field is required.";
			}else if(errors.length == 2){
				response = "The "+errors[0]+" and "+errors[1]+" fields are required.";
			}else{
				var fields = ""
				for(var x = 0; x < errors.length; x++){
					fields += errors[x];
					if(x < errors.length - 1){
						fields += ', ';
					}
					if(x == errors.length - 2){
						fields += 'and ';
					}
				}
				response = "The "+fields+" fields are required.";
			}
			document.querySelector('.response_message').innerHTML = "<span class='error'>"+response+"</span>";
		}
	};

	return options;
})();