/* eslint-disable no-undef */
import {
	getMapsHelper
} from './util/mapsHelper';

export const maps = () => {

	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.getElementById('locations_map'),
				locationElementSelector: ('.location_item'),
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
						<a class="map_pin" href="${el.getAttribute('link')}" target="${el.getAttribute('target')}">
							<img src="/dist/images/mappin.png" width="60" height="60" alt="map pin" />
						</a>
						`
					};
				}
			});

			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				if (document.querySelectorAll('.location_item').length < 2) {
					theMap.setZoom(15);
				} else {
					if (document.getElementById('locations_map_wrapper')) {
						let zoom = theMap.getZoom() + 7;
						theMap.setZoom(zoom);
					}
					let zoom = theMap.getZoom() - 1;
					theMap.setZoom(zoom);
				}
				document.querySelectorAll('.location_item').forEach(i => {
					const markerData = mapHelper.getMarker(i);
					markerData.element.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						i.classList.add('hover');
					});
					markerData.element.addEventListener('mouseleave', () => {
						markerData.element.classList.remove('hover');
						i.classList.remove('hover');
					});
				});
			});
		});
};