export const initMobileNav = () => {	
	document.querySelector('#toggle_mobile_nav').addEventListener('click', () => {
		document.querySelector('#toggle_mobile_nav').toggleAttribute('open');
		document.querySelector('#mobile_nav').toggleAttribute('open');
	});
};

export const doubleClick = () => {
	[].forEach.call(document.querySelectorAll('.d_click'), (el) => {
		el.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if (!el.hasAttribute('clicked') && window.screen.width > 750) {
					e.preventDefault();
					el.setAttribute('clicked', true);
				}
			}
		});
	});
};